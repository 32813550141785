import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Plusicon from "../../assets/image/plus.png";
import Minusicon from "../../assets/image/minus.png";
import { toast } from 'react-toastify'
import { getProductDropDownAction } from '../../redux/actions/productAction';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { RoleContext } from '../../helper/RoleProvider';
import { addFinalMaterialSubProduct, editFinalMaterial } from '../../redux/actions/finalMaterial';
import img from "../../assets/image/select.png";

const SubProduct = ({ show, onHide, mainProdQty }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const projctId = queryParams.get("projectId")
    const [ProductName, setProductName] = useState('W')
    const [productOption, setProductOption] = useState([])
    const [productReq, setProductReq] = useState([
        {
            productId: '',
            productName: '',
            productCode: '',
            reqQuantity: '',
            buyingPrice: '',
            retailSalePrice: '',
            labourCharge: '',
            desc: '',
            make: '',
            unit: '',
            showQty: 0
        },
    ]);
    const [formErrors, setFormErrors] = useState()
    const [rotateArrow, setRotateArrow] = useState('');
    const [projectId, setProjectId] = useState('')
    const getProductData = useSelector((state) => state.get_Product_Drop_Down.getProductDropDown.data)
    const getFinalMaterialByIdData = useSelector((state) => state.get_Final_Material_By_Id.getFinalMaterialById)

    useEffect(() => {
        if (projctId) {
            setProjectId(projctId)
        }
    }, [projctId])

    useEffect(() => {
        dispatch(getProductDropDownAction())
    }, [])
    useEffect(() => {
        if (show?._id?._id) {
            setProductReq([
                {
                    productId: show?._id?.productId?._id,
                    productName: show?._id?.productName,
                    productCode: '',
                    reqQuantity: show?._id?.quantity,
                    buyingPrice: show?._id?.buyingPrice,
                    retailSalePrice: show?._id?.retailSalePrice,
                    labourCharge: show?._id?.labourCharge ? show?._id?.labourCharge : 0,
                    desc: show?._id?.desc ? show?._id?.desc : '',
                    make: show?._id?.make,
                    unit: show?._id?.unit,
                    showQty: getProductData?.find((ele) => ele?._id === show?._id?.productId._id)?.stock
                }
            ])
        }
    }, [show])

    useEffect(() => {
        const data = getProductData?.find((ele) => ele?._id === show?.productId)
        setProductName(data?.productName)
    }, [show])

    useEffect(() => {
        if (getFinalMaterialByIdData && getFinalMaterialByIdData.data) {
            setProductReq([{
                productId: getFinalMaterialByIdData.data.productId,
                productName: getFinalMaterialByIdData.data.productName,
                productCode: '',
                reqQuantity: getFinalMaterialByIdData.data.quantity,
                buyingPrice: getFinalMaterialByIdData.data.buyingPrice,
                retailSalePrice: getFinalMaterialByIdData.data.retailSalePrice,
                labourCharge: getFinalMaterialByIdData.data.labourCharge ? getFinalMaterialByIdData.data.labourCharge : 0,
                desc: getFinalMaterialByIdData.data.desc,
                make: getFinalMaterialByIdData.data.make,
                unit: getFinalMaterialByIdData.data.unit,
                showQty: getProductData?.find((ele) => ele?._id === getFinalMaterialByIdData.data.productId)?.stock
            }])
        }
    }, [getFinalMaterialByIdData])


    const validation = () => {
        let flag = false;
        const error = {};
        if (productReq.length) {
            productReq.map((item, i) => {
                if (item.productId === "") {
                    error[`productCode${i + 1}`] = 'Required'
                    flag = true;
                }
                if (item.reqQuantity === "") {
                    error[`reqQuantity${i + 1}`] = 'Required'
                    flag = true;
                }
                else if (item.reqQuantity == 0) {
                    error[`reqQuantity${i + 1}`] = 'Not valid'
                    flag = true;
                }
                if (item.unit === "") {
                    error[`unit${i + 1}`] = 'Required'
                    flag = true;
                }
            })
        }
        setFormErrors(error);
        return flag;
    };

    const Save = () => {
        if (validation()) {
            return
        }
        if (show?._id?._id) {
            const body = {
                project: projectId,
                productId: productReq[0].productId,
                productName: getProductData.find((e) => e._id === productReq[0].productId)?.productName,
                quantity: productReq[0].reqQuantity,
                labourCharge: +productReq[0].labourCharge,
                retailSalePrice: + productReq[0].retailSalePrice,
                buyingPrice: + productReq[0].buyingPrice,
                parantId: productReq[0].parantId,
                remark: "Manually Added",
                unit: productReq[0].unit,
                make: productReq[0].make,
                desc: productReq[0].desc,
            }
            dispatch(editFinalMaterial({ ...body, id: show?._id?._id }))
        } else {
            const body =
                productReq.map((ele) => {
                    return {
                        project: projectId,
                        productId: ele.productId,
                        productName: getProductData.find((e) => e._id === ele.productId)?.productName,
                        quantity: ele.reqQuantity,
                        labourCharge: +ele.labourCharge,
                        retailSalePrice: + ele.retailSalePrice,
                        buyingPrice: + ele.buyingPrice,
                        parantId: show.parantId,
                        remark: "Manually Added",
                        unit: ele.unit,
                        make: ele.make,
                        desc: ele.desc,
                    }
                })
            dispatch(addFinalMaterialSubProduct(body))
        }
        onHide()
        setProductReq([
            {
                productId: '',
                productName: '',
                productCode: '',
                reqQuantity: '',
                buyingPrice: '',
                retailSalePrice: '',
                labourCharge: '',
                desc: '',
                make: '',
                unit: '',
                showQty: 0
            },
        ]);
    }

    const handleChangeData = (e, i, name) => {
        setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' })
        let val = [...productReq];
        let regex = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (name === 'productCode') {
            val[i].productName = e.label
            const selectProduct = productReq.map(item => item.productId)
            if (selectProduct.includes(e.value)) {
                toast.error("You already selected this Product!");
                val[i].productCode = "";
                val[i].productId = "";
                val[i].showQty = "";
                val[i].unit = "";
            } else {
                let product = getProductData?.find((ele) => ele?._id === e.value)
                val[i].productId = product?._id
                val[i].showQty = product?.stock
                val[i].unit = product?.unitOfMeasurement
                val[i].buyingPrice = product?.buyingPrice
                val[i].retailSalePrice = product?.retailSalePrice
                val[i].labourCharge = product?.labourCharge ? product?.labourCharge : ''
            }
            setProductReq(val);
        } else if (name === "reqQuantity") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                val[i].reqQuantity = e.target.value;
                setProductReq(val);
            }
        } else if (name === "deliveredQuantity") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                val[i].deliveredQuantity = e.target.value;
                setProductReq(val);
            }
        } else if (name === "desc") {
            val[i].desc = e.target.value;
            setProductReq(val);
        } else if (name === "buyingPrice") {
            val[i].buyingPrice = e.target.value;
            setProductReq(val);
        } else if (name === "retailSalePrice") {
            let numberRex = /^(?:\d*\.\d{0,2}|\d+)$/;
            if (
                !e.target.value ||
                e.target.value === "" ||
                numberRex.test(e.target.value)
            ) {
                val[i].retailSalePrice = e.target.value;
                setProductReq(val);
            }
        } else if (name === "labourCharge") {
            let numberRex = /^(?:\d*\.\d{0,2}|\d+)$/;
            if (
                !e.target.value ||
                e.target.value === "" ||
                numberRex.test(e.target.value)
            ) {
                val[i].labourCharge = e.target.value;
                setProductReq(val);
            }
        } else if (name === "make") {
            val[i].make = e.target.value;
            setProductReq(val);
        }
        else if (name === "unit") {
            val[i].unit = e.target.value;
            setProductReq(val);
        }
    };

    const addData = () => {
        let val = [...productReq];
        val.push({
            productId: '',
            productName: '',
            productCode: '',
            reqQuantity: '',
            buyingPrice: '',
            retailSalePrice: '',
            labourCharge: '',
            desc: '',
            make: '',
            unit: '',
            showQty: 0
        });
        setProductReq(val);
    };

    const Remove = (i) => {
        if (productReq.length > 1) {
            let val = [...productReq];
            val.splice(i, 1);
            setProductReq(val);
        }
    }

    const Blur = () => {
        setRotateArrow('')
    }

    const Click = (name) => {
        if (rotateArrow === name) {
            setRotateArrow('')
        } else {
            setRotateArrow(name)
        }
    }

    const selectValue = (value, name) => {
        if (name === "productCode") {
            return productOption.find((ele) => ele.value === value)
        }
    }
    useEffect(() => {
        if (getProductData) {
            let temp = getProductData.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { case: 'upper' }))
            setProductOption(temp.filter((item) => item._id !== show.productId).map((ele) => {
                return {
                    value: ele._id,
                    label: ele.productCode ? `${ele.productName}` : ele.productName
                }
            }))
        }
    }, [getProductData])

    // useEffect(() => {
    //     return () => {
    //         dispatch({
    //             type: GET_FINAL_MATERIAL_BY_ID,
    //             payload: {}
    //         })
    //     }
    // }, [])

    return (
        <Modal
            className='subproduct-modal'
            show={show.open}
            onHide={() => {
                onHide(); setProductReq([
                    {
                        productId: '',
                        productName: '',
                        productCode: '',
                        reqQuantity: '',
                        buyingPrice: '',
                        retailSalePrice: '',
                        labourCharge: '',
                        desc: '',
                        make: '',
                        unit: '',
                        showQty: 0
                    },
                ])
            }}
            centered
            // size="xl"
            dialogClassName="modal-90w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{show?._id?._id ? "Edit Sub Product" : `Add Sub Product ${`(${ProductName})`} ${`(${mainProdQty ?? '-'})`}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='over-flow-scroll'>
                {productReq?.length > 0 && productReq.map((data, i) => (
                    <div key={i}>
                        <Row>
                            <h5 className='mt-4 pt-3' style={{ width: "20px" }}>{i + 1}</h5>
                            <Row>
                                <Col xxl={!show?._id?._id ? 4 : 5} xl={4} lg={6} md={6}>
                                    <div className="mt-3 small-input me-5 me-xl-0 w-100">
                                        <div className="users-form-info w-100">
                                            <div className="multi-select">
                                                <label>Product</label>
                                                <Select
                                                    cacheOptions
                                                    placeholder="Select Product"
                                                    onBlur={() => Blur()}
                                                    value={productOption.find(item => item.value === data.productId)}
                                                    options={productOption}
                                                    onChange={(e) => {
                                                        const option = productOption?.find((item) => item.value === e.value);
                                                        handleChangeData(option, i, 'productCode');
                                                    }}
                                                    style={{ marginTop: "unset", paddingRight: 30 }}
                                                    maxMenuHeight={100}
                                                />
                                            </div>
                                            {data.unit && <label style={{ fontWeight: "bold", textAlign: 'end', width: '100%' }}>{data.unit}</label>}
                                            {formErrors && formErrors[`productCode${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`productCode${i + 1}`]}</label>}
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={1} xl={4} lg={6} md={6}>
                                    <div className="mt-3 small-input me-5 me-xl-0 w-100">
                                        <div className="users-form-info w-100">
                                            <div className="users-label">
                                                <label>Req Qty</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Req qty"
                                                    onChange={(e) => handleChangeData(e, i, 'reqQuantity')}
                                                    value={data.reqQuantity}
                                                />
                                                {data.showQty ? <label title='Final Material' style={{ fontSize: "14px" }}>FM: {data.showQty && data.showQty}</label> : ''}
                                            </div>
                                            {formErrors && formErrors[`reqQuantity${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`reqQuantity${i + 1}`]}</label>}
                                        </div>
                                    </div>
                                </Col>

                                <Col xxl={1} xl={4} lg={6} md={6}>
                                    <div className="mt-3 small-input me-5 me-xl-0 w-100">
                                        <div className="users-form-info w-100">
                                            <div className="users-label">
                                                <label title='Retail Sale Price'>RSP</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Retail Sale price"
                                                    onChange={(e) => handleChangeData(e, i, 'retailSalePrice')}
                                                    value={data.retailSalePrice}
                                                />
                                            </div>
                                            {data.buyingPrice && <label style={{ fontWeight: "bold", textAlign: 'end', width: '100%' }} title='Buying Pr̥ice'>BP: {data.buyingPrice}</label>}
                                            {formErrors?.retailSalePrice && <label style={{ color: "red" }}>{formErrors.retailSalePrice}</label>}
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={1} xl={4} lg={6} md={6}>
                                    <div className="mt-3 small-input me-5 me-xl-0 w-100">
                                        <div className="users-form-info w-100">
                                            <div className="users-label">
                                                <label>LC</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="labour Charge"
                                                    onChange={(e) => handleChangeData(e, i, 'labourCharge')}
                                                    value={data.labourCharge}
                                                />
                                            </div>
                                            {formErrors?.labourCharge && <label style={{ color: "red" }}>{formErrors.labourCharge}</label>}
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={2} xl={4} lg={6} md={6} className="pr">
                                    <div className="mt-3 small-input me-5 me-xl-0 w-100">
                                        <div className="users-form-info w-100">
                                            <div className="users-label">
                                                <label>Description</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Description"
                                                    onChange={(e) => handleChangeData(e, i, 'desc')}
                                                    value={data.desc}
                                                    rows={1}
                                                />
                                            </div>
                                            {formErrors && formErrors[`desc${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`desc${i + 1}`]}</label>}
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={2} xl={4} lg={6} md={6} className="pr">
                                    <div className="mt-3 small-input me-5 me-xl-0 w-100">
                                        <div className="users-form-info w-100">
                                            <div className="users-label">
                                                <label>Make</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Make"
                                                    onChange={(e) => handleChangeData(e, i, 'make')}
                                                    value={data.make}
                                                    rows={1}
                                                />
                                            </div>
                                            {formErrors && formErrors[`make${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`make${i + 1}`]}</label>}
                                        </div>
                                    </div>
                                </Col>
                                {!show?._id?._id && <Col xl={1} lg={2} md={2} sm={2} xs={2} className="ms-xl-0 ms-3 mt-3 mt-xl-5">
                                    {<div className="users-form-info text-xl-center mx-0">
                                        <div className="">
                                            <label> </label>
                                            {i === productReq.length - 1 && (
                                                <img
                                                    src={Plusicon}
                                                    onClick={() => { addData() }}
                                                    width="30"
                                                    style={{ cursor: "pointer" }}
                                                    alt=""
                                                />
                                            )}
                                            {productReq.length > 1 && <img
                                                src={Minusicon}
                                                onClick={() => Remove(i)}
                                                width="35"
                                                style={{ cursor: "pointer" }}
                                                alt=""
                                            />}
                                        </div>
                                    </div>}
                                </Col>}
                            </Row>

                        </Row>
                        {productReq.length > i + 1 && <hr />}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button className='adduser-btn' onClick={() => { Save() }} >{show?._id?._id ? "Edit" : "Save"}</Button>
                <button className='cancel-button' onClick={() => {
                    onHide(); setProductReq([
                        {
                            productId: '',
                            productName: '',
                            productCode: '',
                            reqQuantity: '',
                            buyingPrice: '',
                            retailSalePrice: '',
                            labourCharge: '',
                            desc: '',
                            make: '',
                            unit: '',
                            showQty: 0
                        },
                    ]);
                }}>Cancel</button>
            </Modal.Footer>
        </Modal>
    )
}

export default SubProduct