import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import img from "../../assets/image/select.png";
import Plusicon from "../../assets/image/plus.png";
import Minusicon from "../../assets/image/minus.png";
import { toast } from 'react-toastify'
import { addPurchaseRequestAction, editPurchaseRequestAction, getAllPurchaseRequestAction, getPurchaseRequestByIdAction, getPurchaseRequestByProjectAction } from '../../redux/actions/purchaseRequest';
import { getProductByFinalMaterialAction, getProductDropDownAction } from '../../redux/actions/productAction';
import { useLocation } from 'react-router-dom';
import { ADD_PURCHASE_REQUEST, EDIT_PURCHASE_REQUEST } from '../../redux/type';
import { getAllProjectAction } from '../../redux/actions/projectAction';
import { Priority, Unit } from '../../helper/Constant';
import Select from 'react-select';
import { UserRoles } from '../../helper/Constant';
import { RoleContext } from '../../helper/RoleProvider';
import { getFinalMaterialByProject } from '../../redux/actions/finalMaterial';
import SweetAlert from 'react-bootstrap-sweetalert';

const AddPurchaseRequest = ({ show, onHide, addPRId, PR }) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const projctId = queryParams.get("projectId")
    const [remark, setRemark] = useState('')
    const [project, setProject] = useState('')
    const [PRData, setPRData] = useState({})
    const [productReq, setProductReq] = useState([
        {
            productId: '',
            productName: '',
            productCode: '',
            reqQuantity: '',
            pre_reqQuantity: '',
            pre_deliveredQuantity: '',
            deliveredQuantity: 0,
            desc: '',
            make: '',
            priority: '',
            unit: '',
            showQty: 0,
            remainingQty: 0
        },
    ]);
    const purchaseRequestAdded = () => toast.success('Purchase request Added');
    const purchaseRequestUpdate = () => toast.success('Purchase request update Successfully');
    const PRError = () => toast.error("Purchase Request not found");
    const [formErrors, setFormErrors] = useState()
    const [rotateArrow, setRotateArrow] = useState('');
    const [product, setProduct] = useState([])
    const [finalMaterial, setFinalMaterial] = useState([])
    const [projectId, setProjectId] = useState('')
    const [projectType, setProjectType] = useState('')
    const [projectOption, setProjectOption] = useState([])
    const [extraUsedProducts, setExtraUsedProducts] = useState([])
    const [showConfirmation, setShowConfirmation] = useState(false)
    const getPurchaseRequestByIdData = useSelector((state) => state.get_Purchase_Request_By_Id.getPurchaseRequestById)
    const addPurchaseRequestData = useSelector((state) => state.add_Purchase_Request.addPurchaseRequest)
    const editPurchaseRequestData = useSelector((state) => state.edit_Purchase_Request.editPurchaseRequest)
    const getAllProject = useSelector((state) => state.get_All_Project.getAllProject.data)
    const getProductData = useSelector((state) => state.get_Product_Drop_Down.getProductDropDown.data)
    const getFinalMaterial = useSelector((state) => state.get_Final_Material_By_Project.getFinalMaterialByProject.data)
    const purchaseRequestByProjectId = useSelector((state) => state.get_Purchase_Request_By_Project.getPurchaseRequestByProject)
    const userRole = useContext(RoleContext);
    const RolesForEdit = [UserRoles.Admin];
    const [status, setStatus] = useState("Waiting For Approval");
    const [save_and_approved_PR, setSave_And_Approved_PR] = useState(false);
    const [rejected_PR, setRejected_PR] = useState(false);
    const [status1, setStatus1] = useState(false);

    useEffect(() => {
        if (projctId) {
            setProjectId(projctId)
        }
    }, [projctId])

    useEffect(() => {
        if (getFinalMaterial?.data?.length)
            setFinalMaterial(getFinalMaterial?.data?.map(item => { return { ...item, productId: item?.productId?._id, productCode: item?.productId?.productCode } }))
        else
            setFinalMaterial([])

    }, [getFinalMaterial])

    useEffect(() => {
        if (projctId || project) {
            dispatch(getProductDropDownAction())
            dispatch(getFinalMaterialByProject(projctId || project))
        }
    }, [projctId, project])

    useEffect(() => {
        if (project) {
            dispatch(getPurchaseRequestByProjectAction(project))
        }
    }, [project]);

    useEffect(() => {
        if (addPRId !== "") {
            dispatch(getPurchaseRequestByIdAction(addPRId))
        }
    }, [addPRId])

    useEffect(() => {
        if (!projectId) {
            let body = '';
            let role = '';
            if (userRole === UserRoles.Maintance) {
                role = 'Maintenance'
            } else if (userRole === UserRoles.Project) {
                role = 'Project'
            }
            dispatch(getAllProjectAction(body, role))
        }
    }, [projectId])

    useEffect(() => {
        if (getAllProject) {
            let temp = getAllProject.sort((a, b) => a.partyName.localeCompare(b.partyName, undefined, { case: 'upper' }))
            setProjectOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.partyName
                }
            }))
        }
    }, [getAllProject])

    useEffect(() => {
        if (getProductData) {
            let temp = getProductData.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { case: 'upper' }));
            let selectedProducts = productReq.map(item => item.productCode);
            let finalMaterialData = finalMaterial.map(item => {
                return item.productId
            });
            let productOptions = temp.filter(it => finalMaterialData.includes(it._id)).map((item) => {
                // let productOptions = (finalMaterialData.length ? temp.filter(it => finalMaterialData.includes(it._id)) : temp).map((item) => {
                return {
                    value: item.productCode,
                    label: item.productCode ? `${item.productName}` : item.productName
                }
            });
            setProduct(productOptions.filter(item => !selectedProducts.includes(item.productCode)));
        }
    }, [getProductData, finalMaterial, productReq])

    useEffect(() => {
        if (project) {
            setProjectType(getAllProject && getAllProject.find((ele) => ele?._id === project)?.projectType)
        } else {
            setProjectType('')
        }
    }, [project, getAllProject])

    const getRemainingQuantity = (productCode) => {

        const availableQtys = finalMaterial?.filter((item) => item?.productCode === productCode).reduce((acc, curr) => acc + curr.quantity, 0);
        const D1 = purchaseRequestByProjectId?.data?.map((item1) => {
            if (addPRId) {
                if (location.pathname === "/purchaserequests") {
                    if (item1.status === "Delivered") {
                        return item1?.items?.find(item2 => item2?.productCode == productCode)?.deliveredQuantity
                    }
                } else if (location.pathname === "/purchaserequest") {
                    if (item1.status === "Delivered") {
                        return item1?.items?.find(item2 => item2?.productCode == productCode)?.deliveredQuantity
                    } else if (item1.status === "Pending" || item1.status === "Waiting For Approval") {
                        return item1?.items?.find(item2 => item2?.productCode == productCode)?.reqQuantity
                    }
                }
            } else {
                if (item1.status === "Delivered") {
                    return item1?.items?.find(item2 => item2?.productCode == productCode)?.deliveredQuantity;
                } else if (item1.status === "Pending" || item1.status === "Waiting For Approval") {
                    return item1?.items?.find(item2 => item2?.productCode == productCode)?.reqQuantity
                }
            }
        });

        const D2 = D1?.filter(item => item != undefined);
        const requestedQty = D2?.reduce((curr, next) => curr + next, 0);
        return (availableQtys - requestedQty);
    }

    useEffect(() => {
        if (getPurchaseRequestByIdData.message === "Purchase Request not found") {
            onHide()
            PRError(true)
        } else if (getPurchaseRequestByIdData && getPurchaseRequestByIdData.data && addPRId) {

            setProductReq(() => {
                let data = getPurchaseRequestByIdData.data.items.map((ele) => {

                    const availableQty = finalMaterial?.filter((item) => item?.productCode === ele.productCode).reduce((acc, curr) => acc + curr.quantity, 0);
                    const remainingQty = getRemainingQuantity(ele.productCode);

                    return {
                        productCode: ele.productCode,
                        // productName: ele.productName,
                        // productCode: product.find(item=> item.value === ele.productCode).value,
                        productName: ele.productName,
                        reqQuantity: ele.reqQuantity,
                        pre_reqQuantity: ele.reqQuantity,
                        pre_deliveredQuantity: ele.deliveredQuantity,
                        deliveredQuantity: ele.deliveredQuantity,
                        desc: ele.desc,
                        make: ele.make,
                        priority: ele.priority,
                        unit: ele.unit,
                        showQty: availableQty,
                        remainingQty: remainingQty,
                    }
                });
                return data;
            });

            setStatus(getPurchaseRequestByIdData.data.status)
            setProject(getPurchaseRequestByIdData.data.project)
            setRemark(getPurchaseRequestByIdData.data.remark)
        }
    }, [getPurchaseRequestByIdData, finalMaterial, purchaseRequestByProjectId])

    useEffect(() => {
        if (purchaseRequestByProjectId?.data && (status1)) {

            const getExtaQtyPromocode = () => {
                if (location.pathname === "/purchaserequests") {
                    // Save PR in Store
                    if (getPurchaseRequestByIdData?.data?.status === "Delivered") {
                        return [];
                    } else {
                        const newData = productReq.filter((item) => (Number(item.deliveredQuantity) ?? 0) > (Number(item.reqQuantity) ?? 0) || (Number(item.deliveredQuantity) ?? 0) > (Number(item.remainingQty) ?? 0));
                        const moreQtyPromocode = newData.map((element) => element.productCode);
                        return moreQtyPromocode;
                    }
                } else {
                    if (PRData.status === "Pending") {
                        // Save & Approved PR by Admin
                        return [];
                    } else if (PRData.status === "Waiting For Approval" && addPRId == "") {
                        // Add and Save PR
                        const newData = productReq.filter((item) => Number(item.reqQuantity) > Number(item.remainingQty));
                        const moreQtyPromocode = newData.map((element) => element.productCode);
                        return moreQtyPromocode;
                    } else if (PRData.status === "Waiting For Approval" && addPRId != "") {
                        // Edit and Save PR
                        const newData = productReq.filter((item) => Number(item.reqQuantity) > (Number(item.remainingQty) + Number(item.pre_reqQuantity)));
                        console.log(newData)
                        const moreQtyPromocode = newData.map((element) => element.productCode);
                        return moreQtyPromocode;
                    } else if (PRData.status === "Rejected" && addPRId != "") {
                        // Edit and Save PR
                        return [];
                    } else {
                        return [];
                    }
                }
            }

            const moreQtyPromocode = getExtaQtyPromocode();

            setExtraUsedProducts(moreQtyPromocode);
            if (moreQtyPromocode.length) {
                setShowConfirmation(true)
            } else {
                if (addPRId) {
                    if (Object.keys(PRData).length > 0) {
                        dispatch(editPurchaseRequestAction(PRData))
                    }
                } else {
                    if (Object.keys(PRData).length > 0) {
                        dispatch(addPurchaseRequestAction(PRData))
                    }
                }
            }

        } else {
            if (addPRId) {
                if (Object.keys(PRData).length > 0) {
                    dispatch(editPurchaseRequestAction(PRData))
                }
            } else {
                if (Object.keys(PRData).length > 0) {
                    dispatch(addPurchaseRequestAction(PRData))
                }
            }
        }
    }, [purchaseRequestByProjectId, finalMaterial]);

    const validation = () => {
        let flag = false;
        const error = {};
        if (!status) {
            error.status = "Please select status";
            flag = true;
        }
        if (!projectId && !project) {
            error.project = "Please select project";
            flag = true;
        }
        if (productReq.length) {
            productReq.map((item, i) => {
                if (item.productCode === "") {
                    error[`productCode${i + 1}`] = 'Required'
                    flag = true;
                }
                if (item.reqQuantity === "") {
                    error[`reqQuantity${i + 1}`] = 'Required'
                    flag = true;
                }
                else if (item.reqQuantity == 0) {
                    error[`reqQuantity${i + 1}`] = 'Not valid'
                    flag = true;
                }
                if ((item.deliveredQuantity === "" && PR === "store" && status === "Delivered")) {
                    error[`deliveredQuantity${i + 1}`] = 'Required'
                    flag = true;
                }
                // if (item.priority === "") {
                //     error[`priority${i + 1}`] = 'Required'
                //     flag = true;
                // }
            })
        }
        setFormErrors(error);
        return flag;
    };

    const Save = (approval_status) => {
        if (validation()) {
            return
        }
        const body = {
            project: projectId ? projectId : project,
            status: approval_status,
            remark: remark,
            items: productReq.map((ele) => {
                return {
                    productCode: ele.productCode,
                    productName: getProductData.find((e) => e.productCode === ele.productCode).productName,
                    // productName: ele.productName,
                    reqQuantity: ele.reqQuantity,
                    unit: ele.unit,
                    priority: Priority.Medium,
                    make: ele.make,
                    desc: ele.desc,
                    deliveredQuantity: ele.deliveredQuantity,
                }
            })
        }
        setPRData(body)
        dispatch(getPurchaseRequestByProjectAction(body.project))
    }

    useEffect(() => {
        if (addPurchaseRequestData && addPurchaseRequestData.data) {
            if (addPurchaseRequestData.message === "Purchase Request created successfully.") {
                purchaseRequestAdded(true)
                setShowConfirmation(false)
                dispatch({
                    type: ADD_PURCHASE_REQUEST,
                    payload: []
                })
                projectId && dispatch(getPurchaseRequestByProjectAction(projectId))
                onHide()
            }
        }
    }, [addPurchaseRequestData])

    const Edit = (approval_status) => {

        if (validation()) {
            return
        }

        const body = {
            id: addPRId,
            project: projectId ? projectId : project,
            status: approval_status,
            remark: remark,
            items: productReq.map((ele) => {
                return {
                    productCode: ele.productCode,
                    productName: getProductData.find((e) => e.productCode === ele.productCode).productName,
                    // productName: ele.productName,
                    reqQuantity: ele.reqQuantity,
                    unit: ele.unit,
                    priority: ele.priority,
                    make: ele.make,
                    desc: ele.desc,
                    deliveredQuantity: ((ele.deliveredQuantity === "") && (PR === 'store') && (status !== "Delivered")) ? 0 : ele.deliveredQuantity,
                }
            })
        }
        setPRData(body)
        dispatch(getPurchaseRequestByProjectAction(body.project));
        setStatus1(true);
        setSave_And_Approved_PR(false);
        setRejected_PR(false);
    }

    useEffect(() => {
        if (editPurchaseRequestData && editPurchaseRequestData.data) {
            if (editPurchaseRequestData.data.message === "Purchase Request updated successfully.") {
                purchaseRequestUpdate(true)
                setShowConfirmation(false)
                dispatch({
                    type: EDIT_PURCHASE_REQUEST,
                    payload: []
                })
                setSave_And_Approved_PR(false);
                setRejected_PR(false);
                projectId && dispatch(getPurchaseRequestByProjectAction(projectId))
                onHide()
            }
        }
    }, [editPurchaseRequestData])

    useEffect(() => {
        return (() => {
            setPRData({})
            setExtraUsedProducts([])
        })
    }, [])

    const handleChangeData = (e, i, name) => {
        setFormErrors({ ...formErrors, [`${name}${i + 1}`]: '' })
        let val = [...productReq];
        let regex = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (name === 'productCode') {
            // val[i].productCode = e.value
            val[i].productName = e.label
            if (productReq.map(item => item.productCode).includes(e.value)) {
                toast.error("You already selected this Product!");
                val[i].productCode = "";
                val[i].showQty = 0;
                val[i].remainingQty = 0;
                val[i].unit = "";

                val[i].reqQuantity = "";
                val[i].deliveredQuantity = 0;
                val[i].desc = "";
                val[i].make = "";
                val[i].priority = "";

            } else {
                let productCode = getProductData?.find((ele) => ele?.productCode === e.value)?.productCode;
                val[i].productCode = productCode;
                let productId = getProductData?.find((ele) => ele?.productCode === e.value)?._id
                val[i].showQty = finalMaterial?.length ? finalMaterial?.filter((ele) => ele?.productId === productId).reduce((acc, curr) => acc + curr.quantity, 0) : getProductData?.find((ele) => ele?.productCode === e.value)?.stock
                let remainingQty = getRemainingQuantity(productCode);
                val[i].remainingQty = remainingQty;
                val[i].unit = finalMaterial?.length ? finalMaterial?.find((ele) => ele?.productId === productId)?.unit : "";
            }
            setProductReq(val);
            // val[i].showQty = getFinalMaterial?.find((ele) => ele?.productId === productId)?.quantity
            // setProduct(product.filter(prod => prod?.value !== val[i].productCode))
        } else if (name === "reqQuantity") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                val[i].reqQuantity = e.target.value;
                setProductReq(val);
            }
        } else if (name === "deliveredQuantity") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                val[i].deliveredQuantity = e.target.value;
                setProductReq(val);
            }
        } else if (name === "desc") {
            val[i].desc = e.target.value;
            setProductReq(val);
        } else if (name === "make") {
            val[i].make = e.target.value;
            setProductReq(val);
        } else if (name === "priority") {
            val[i].priority = e.target.value;
            setProductReq(val);
        } else if (name === "unit") {
            val[i].unit = e.target.value;
            setProductReq(val);
        }
    };

    const addData = () => {

        let val = [...productReq];
        val.push({
            productName: '',
            productCode: '',
            reqQuantity: '',
            deliveredQuantity: 0,
            desc: '',
            make: '',
            priority: '',
            unit: '',
            showQty: 0,
            remainingQty: 0
        });
        setProductReq(val);
    };

    const Remove = (i) => {
        if (PR !== 'store') {
            if (productReq.length > 1) {
                let val = [...productReq];
                val.splice(i, 1);
                setProductReq(val);
            }
        }
    }

    const Blur = () => {
        setRotateArrow('')
    }

    const Click = (name) => {
        if (rotateArrow === name) {
            setRotateArrow('')
        } else {
            setRotateArrow(name)
        }
    }

    const selectValue = (value, name) => {
        if (name === "project") {
            return projectOption.find((ele) => ele.value === value)
        } else if (name === "productCode") {
            return product.find((ele) => ele.value === value)
        }
    }

    const handleChangeProject = (e) => {
        setFormErrors({ ...formErrors, project: '' })
        setProject(e.value)
        setProductReq([{
            productId: '',
            productName: '',
            productCode: '',
            reqQuantity: '0',
            deliveredQuantity: '0',
            desc: '',
            make: '',
            priority: '',
            unit: '',
            showQty: '0'
        }])
    }

    const onConfirm = () => {
        if (addPRId) {
            dispatch(editPurchaseRequestAction({ ...PRData, adminNotification: extraUsedProducts.join(",") }))
        } else {
            dispatch(addPurchaseRequestAction({ ...PRData, adminNotification: extraUsedProducts.join(",") }))
        }
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         setStatus1(true);
    //     }, 3000);
    // }, []);

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            // size="xl"
            dialogClassName="modal-90w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>{(addPRId && PR === "store") ? "Purchase Request" : addPRId ? "Edit Purchase Request" : "Add Purchase Request"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='over-flow-scroll'>
                {addPRId && <Row>
                    <Col lg={3}>
                        <div className="small-input mt-2">
                            <div className="users-form-info ms-3 me-5">
                                <div className="users-label">
                                    <label>Purchase Request Number : {getPurchaseRequestByIdData?.data?.purchaseRequestNumber}</label>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>}
                <Row>
                    {!projectId && <Col lg={3}>
                        <div className="small-input mt-2">
                            <div className="users-form-info ms-3">
                                <div className="users-form-info add-remark-modal mx-0">
                                    <div className="multi-select">
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <label>Project</label>
                                            {projectType && <label> ( {projectType} )</label>}
                                        </div>
                                        {/* <Select
                                            value={selectValue(project, "project")}
                                            onChange={(e) => handleChangeProject(e)}
                                            options={projectOption}
                                            isDisabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered" || addPRId !== ""}
                                        /> */}
                                        <div className="users-label">
                                            <div className="position-relative select-icon">
                                                <img src={img} className={`${rotateArrow === `priority-X1Y1Z1` && "rotateArrow"}`} alt="" style={{ marginTop: 2, marginRight: 4 }} />
                                                <select
                                                    className="form-control"
                                                    value={project}
                                                    onBlur={() => Blur()}
                                                    onClick={() => Click(`priority-X1Y1Z1`)}
                                                    onChange={(e) => {
                                                        const option = projectOption?.find((item) => item.value === e.target.value);
                                                        handleChangeProject(option);
                                                    }}
                                                    style={{ marginTop: "unset", paddingRight: 30 }}
                                                    disabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered" || addPRId !== ""}
                                                >
                                                    <option value="" style={{ display: "none" }}>Select Project</option>
                                                    {projectOption?.map((ele, index) => <option key={index} value={ele.value}>{ele.label}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {formErrors?.project && <span style={{ color: "red" }}>{formErrors.project}</span>}
                            </div>
                        </div>
                    </Col>}

                    {PR === "store" && <Col lg={3}>
                        <div className="small-input mt-3">
                            <div className="users-form-info ms-3 me-5">
                                <div className="users-label">
                                    <label>Status</label>
                                    <div className="users-label">
                                        <div className="position-relative select-icon">
                                            <img src={img} className={`${rotateArrow === 'status' && "rotateArrow"}`} alt="" />
                                            <select className="form-control" value={status} onBlur={() => Blur()} onClick={() => Click('status')} onChange={(e) => { setStatus(e.target.value); setFormErrors({ ...formErrors, status: '' }) }} disabled={getPurchaseRequestByIdData?.data?.status === "Delivered"}>
                                                <option value="">Select status</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Delivered">Delivered</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {formErrors?.status && <span style={{ color: "red" }}>{formErrors.status}</span>}
                            </div>
                        </div>
                    </Col>}
                    {PR === "store" && <Col lg={3}>
                        <div className="mt-3 small-input me-5 me-xl-0">
                            <div className="users-form-info ms-3 w-100">
                                <div className="users-label">
                                    <label>Remarks</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        style={{ height: '47px' }}
                                        placeholder="Enter Remarks"
                                        onChange={(e) => setRemark(e.target.value)}
                                        value={remark}
                                        rows={1}
                                        disabled={getPurchaseRequestByIdData?.data?.status === "Delivered"}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>}
                    {/* {projectType && <Col lg={1} className='d-flex justify-content-between align-items-center'>
                        <label className='mt-3'> ( {projectType} )</label>

                         <div className="mt-3 small-input">
                            <div className="users-form-info ms-3 w-100">
                                <div className="users-label">
                                    <label>Project Type</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={projectType}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div> 
                    </Col>} */}
                </Row>
                {!projectId && <hr />}
                {productReq.length > 0 && productReq.map((data, i) => (
                    <div key={i}>
                        <Row>
                            <Col xs={1} style={{ width: '30px' }}>
                                <h5 className='mt-4 pt-3'>{i + 1}</h5>
                            </Col>
                            <Col xl={11} sm={12} xxl={11} className='flex-grow-1'>
                                <Row>
                                    <Col xxl={PR === 'store' ? 4 : 5} xl={3} lg={4} md={4} sm={6} xs={12}>
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-0 w-100">
                                                <div className="multi-select">
                                                    <label>Product</label>
                                                    {/* <Select
                                                        placeholder="Select Product"
                                                        value={data.productCode && selectValue(data.productCode, "productCode")}
                                                        onChange={(e) => handleChangeData(e, i, 'productCode')}
                                                        options={product}
                                                        height={'auto'}
                                                        isDisabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered"}
                                                    /> */}
                                                    {/* <div className="users-label">
                                                        <div className="position-relative select-icon" >
                                                            <img src={img} className={`${rotateArrow === `priority${i}-${i}` && "rotateArrow"}`} alt="" style={{ marginTop: 2, marginRight: 4 }} />
                                                            <select
                                                                className="form-control"
                                                                value={data?.productCode}
                                                                onBlur={() => Blur()}
                                                                onClick={() => Click(`priority${i}-${i}`)}
                                                                onChange={(e) => {
                                                                    const option = product.find((item) => item.value === e.target.value);
                                                                    handleChangeData(option, i, 'productCode');
                                                                }}
                                                                style={{ marginTop: "unset", paddingRight: 30 }}
                                                                disabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered" || getPurchaseRequestByIdData?.data?.status === "Pending"}
                                                            >
                                                                <option value="" style={{ display: "none" }}>Select Product</option>
                                                                {product?.map((ele, index) => <option key={index} value={ele.value} style={{ overflowWrap: "break-word" }}>{ele.label}</option>)}
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    <Select
                                                        cacheOptions
                                                        placeholder="Select Product"
                                                        onBlur={() => Blur()}
                                                        value={product.find(item => item.value === data.productCode)}
                                                        options={product}
                                                        onChange={(e) => {
                                                            const option = product?.find((item) => item.value === e.value);
                                                            handleChangeData(option, i, 'productCode');
                                                        }}
                                                        isDisabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered" || getPurchaseRequestByIdData?.data?.status === "Pending"}
                                                        style={{ marginTop: "unset", paddingRight: 30, minHeight: 42 }}
                                                        maxMenuHeight={100}
                                                    />
                                                </div>
                                                {data.unit && <label style={{ fontWeight: "bold", textAlign: 'end', width: '100%' }}>{data.unit}</label>}
                                                {formErrors && formErrors[`productCode${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`productCode${i + 1}`]}</label>}
                                                {/* {productReq[i].productCode && <label>Product Code : {productReq[i].productCode}</label>} */}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} xl={2} lg={4} md={4} sm={6} xs={12}>
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-0 w-100">
                                                <div className="users-label">
                                                    <label>Req Qty</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Req qty"
                                                        onChange={(e) => handleChangeData(e, i, 'reqQuantity')}
                                                        value={data.reqQuantity}
                                                        disabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered" || getPurchaseRequestByIdData?.data?.status === "Pending"}
                                                    />
                                                    {(data.productCode && location.pathname !== "/purchaserequests" && addPRId === "") && <label style={{ fontSize: "14px" }} title='Remaining Quantity'> RQ:&nbsp;{((data.remainingQty >= 0) && (data.reqQuantity <= data.showQty)) ? (data.remainingQty - data.reqQuantity) >= 0 ? (data.remainingQty - data.reqQuantity) : 0 : 0}/{data.showQty ? data.showQty : 0}</label>}
                                                    {(data.productCode && location.pathname !== "/purchaserequests" && addPRId !== "") && <label style={{ fontSize: "14px" }} title='Remaining Quantity'> RQ:&nbsp;{((Number(data.remainingQty ?? 0) >= 0) && ((Number(data.reqQuantity) ? Number(data.reqQuantity) : 0) <= data.showQty)) ? ((((Number(data.remainingQty ?? 0) + (Number(data.pre_reqQuantity) ? Number(data.pre_reqQuantity) : 0)) - (Number(data.reqQuantity) ? Number(data.reqQuantity) : 0)) >= 0 ? ((Number(data.remainingQty ?? 0) + (Number(data.pre_reqQuantity) ? Number(data.pre_reqQuantity) : 0)) - (Number(data.reqQuantity) ? Number(data.reqQuantity) : 0)) : 0)) : 0}/{data.showQty ? data.showQty : 0}</label>}
                                                </div>
                                                {formErrors && formErrors[`reqQuantity${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`reqQuantity${i + 1}`]}</label>}
                                            </div>
                                        </div>
                                    </Col>
                                    {PR === 'store' && <Col xxl={2} xl={3} lg={4} md={4} sm={6} xs={12}>
                                        <div className="mt-3 small-input me-5 me-xl-0 w-100">
                                            <div className="users-form-info ms-0 w-100">
                                                <div className="users-label">
                                                    <label>Delv Qty</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter delivered qty"
                                                        onChange={(e) => handleChangeData(e, i, 'deliveredQuantity')}
                                                        value={Boolean(data.deliveredQuantity) ? data.deliveredQuantity : ""}
                                                        disabled={getPurchaseRequestByIdData?.data?.status === "Delivered"}
                                                    />
                                                </div>
                                                {/* {(data.productCode && location.pathname === "/purchaserequests") && <label style={{ fontSize: "14px", fontWeight: "bold" }} title='Remaining Quantity'> RQ:&nbsp;{(data.remainingQty >= 0 && data.deliveredQuantity <= data.showQty) ? (data.remainingQty - data.deliveredQuantity >= 0 ? (data.remainingQty - data.deliveredQuantity) : 0) + ((data.pre_deliveredQuantity === data.deliveredQuantity) ? Number(data.pre_deliveredQuantity) : 0) : 0}/{data.showQty ? data.showQty : 0}</label>} */}
                                                {(data.productCode && location.pathname === "/purchaserequests") && <label style={{ fontSize: "14px", fontWeight: "bold" }} title='Remaining Quantity'> RQ:&nbsp;{((Number(data.remainingQty ?? 0) >= 0) && ((Number(data.deliveredQuantity) ? Number(data.deliveredQuantity) : 0) <= data.showQty)) ? ((((Number(data.remainingQty ?? 0)) - (Number(data.deliveredQuantity) ? Number(data.deliveredQuantity) : 0)) >= 0 ? ((Number(data.remainingQty ?? 0)) - (Number(data.deliveredQuantity) ? Number(data.deliveredQuantity) : 0)) : 0)) : 0}/{data.showQty ? data.showQty : 0}</label>}
                                                {formErrors && formErrors[`deliveredQuantity${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`deliveredQuantity${i + 1}`]}</label>}
                                            </div>
                                        </div>
                                    </Col>}
                                    {/* <Col xxl={2} xl={3} lg={4} md={3} sm={6} xs={12}>
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-0 w-100">
                                                <div className="users-label">
                                                    <label>Priority</label>
                                                    <div className="users-label">
                                                        <div className="position-relative select-icon" >
                                                            <img src={img} className={`${rotateArrow === `priority${i}` && "rotateArrow"}`} alt="" style={{ marginTop: 2, marginRight: 4 }} />
                                                            <select className="form-control" value={data.priority} onBlur={() => Blur()} onClick={() => Click(`priority${i}`)} onChange={(e) => handleChangeData(e, i, 'priority')} disabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered"}>
                                                                <option value="">Priority</option>
                                                                <option value={Priority.Low}>Low</option>
                                                                <option value={Priority.Medium}>Medium</option>
                                                                <option value={Priority.High}>High</option>
                                                                <option value={Priority.Urgent}>Urgent</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formErrors && formErrors[`priority${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`priority${i + 1}`]}</label>}
                                            </div>
                                        </div>
                                    </Col> */}
                                    <Col xxl={2} xl={3} lg={4} md={3} sm={6} xs={12} className="pr">
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-0 w-100">
                                                <div className="users-label">
                                                    <label>Description</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        onChange={(e) => handleChangeData(e, i, 'desc')}
                                                        value={data.desc}
                                                        disabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered"}
                                                        rows={1}
                                                    />
                                                </div>
                                                {formErrors && formErrors[`desc${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`desc${i + 1}`]}</label>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={2} xl={3} lg={4} md={3} sm={6} xs={12} className="pr">
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-0 w-100">
                                                <div className="users-label">
                                                    <label>Make</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Make"
                                                        onChange={(e) => handleChangeData(e, i, 'make')}
                                                        value={data.make}
                                                        rows={1}
                                                        disabled={PR === 'store' || getPurchaseRequestByIdData?.data?.status === "Delivered"}
                                                    />
                                                </div>
                                                {formErrors && formErrors[`make${i + 1}`] && <label style={{ color: "red" }}>{formErrors[`make${i + 1}`]}</label>}
                                            </div>
                                        </div>
                                    </Col>
                                    {PR !== 'store' && <Col xxl={1} xl={2} lg={2} md={3} sm={6} xs={12} className='d-flex align-items-end align-items-xxl-center mt-3 mt-md-0 mt-xxl-4'>
                                        {getPurchaseRequestByIdData?.data?.status !== "Delivered" &&
                                            <div className="mb-1 small-input">
                                                <div className="users-form-info ms-0 w-100">
                                                    <div className="">
                                                        {i === productReq.length - 1 && (
                                                            <img
                                                                src={Plusicon}
                                                                className='me-1'
                                                                onClick={() => { addData() }}
                                                                width="30"
                                                                style={{ cursor: "pointer" }}
                                                                alt=""
                                                            />
                                                        )}
                                                        {productReq.length > 1 &&
                                                            <img
                                                                src={Minusicon}
                                                                className='ms-1'
                                                                onClick={() => Remove(i)}
                                                                width="35"
                                                                style={{ cursor: "pointer" }}
                                                                alt=""
                                                            />}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Col>}
                                </Row>
                            </Col>
                        </Row>
                        {productReq.length > i + 1 && <hr />}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                {(RolesForEdit.includes(userRole) && addPRId && status === "Waiting For Approval" && location.pathname !== "/purchaserequests") && <Button className='adduser-btn' onClick={() => setSave_And_Approved_PR(true)}> Save & Approved </Button>}
                {(RolesForEdit.includes(userRole) && addPRId && status === "Waiting For Approval" && location.pathname !== "/purchaserequests" && getPurchaseRequestByIdData?.data?.status !== "Rejected") && <Button className='adduser-btn' onClick={() => setRejected_PR(true)}> Rejected </Button>}
                <Button className='adduser-btn' onClick={() => { addPRId ? Edit(status) : Save(status) }} disabled={getPurchaseRequestByIdData?.data?.status === "Delivered"}>{(addPRId && PR === "store") ? "Save" : addPRId ? "Save" : "Save"}</Button>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer>
            {showConfirmation &&
                <SweetAlert
                    danger
                    showCancel
                    confirmBtnText={"Ok"}
                    confirmBtnBsStyle={"danger"}
                    title="Are you sure?"
                    onConfirm={() => { onConfirm() }}
                    onCancel={() => { setShowConfirmation(true), onHide() }}
                    focusCancelBtn
                >Your {PR === "store" ? "Delivered" : "Requested"} product is more then final material. <br />In this case we send one notification to admin.

                </SweetAlert>
            }

            <SweetAlert
                show={save_and_approved_PR}
                type='primary'
                showCancel
                confirmBtnText="Approved"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle='outline-primary'
                title="Are you sure to approved this purchase request?"
                onConfirm={() => Edit("Pending")}
                onCancel={() => setSave_And_Approved_PR(false)}
                focusCancelBtn
            />

            <SweetAlert
                show={rejected_PR}
                danger
                showCancel
                confirmBtnText="Reject"
                confirmBtnBsStyle="danger"
                title="Are you sure to reject this purchase request?"
                onConfirm={() => Edit("Rejected")}
                onCancel={() => setRejected_PR(false)}
                focusCancelBtn
            />
        </Modal>
    )
}

export default AddPurchaseRequest