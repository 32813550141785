import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import numberToWords from 'number-to-words';
import logo from "../assets/img/logo.bmp"

export const getCookie = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");
  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
};

export const dateFunction = (date) => {
  if (
    moment(date).format("YYYY.MM.DD") ===
    moment(new Date()).format("YYYY.MM.DD")
  ) {
    return "Today";
  } else if (
    moment(date).format("YYYY.MM.DD") ===
    moment(new Date()).subtract(1, "days").format("YYYY.MM.DD")
  ) {
    return "Yesterday";
  }
  return moment(date).format("YYYY.MM.DD");
};


export const checkImageURL = (nationality) => {
  const pngImages = ["Antarctica"];

  let url_image = `./img/flags/${nationality}.svg`;
  if (pngImages.includes(nationality)) {
    url_image = `./img/flags/${nationality}.png`;
  }
  return url_image
}

export const xwwwFormUrlencoded = (formData) => {
  var formBody = [];
  for (var property in formData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(formData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return formBody
}

export const dateFormate = (date, type) => {
  if (type === 'FullDateTime') {
    return moment(date).format('DD MMM YYYY hh:mm A')
  } else if (type === 'Date') {
    return moment(date).format('DD MMM YYYY')
  } else if (type === 'Time') {
    return moment(date).format('hh:mm A')
  }
}

export const downloadFile = (data, name, showAmmount) => {
  let totalQuantity = 0
  let entries = data.purchaseItems.map((ele, ind) => {
    totalQuantity = totalQuantity + ele.quantity
    return showAmmount ? [
      { text: ind + 1, alignment: 'center', border: [true, false, true, false] },
      { text: ele.productName, border: [true, false, true, false] },
      { text: ele.quantity, alignment: 'center', border: [true, false, true, false] },
      { text: ele.rate, alignment: 'center', border: [true, false, true, false] },
      { text: ele.amount, alignment: 'center', border: [true, false, true, false] }
    ] :
      [
        { text: ind + 1, alignment: 'center', border: [true, false, true, false] },
        { text: ele.productName, border: [true, false, true, false] },
        { text: ele.quantity, alignment: 'center', border: [true, false, true, false], colSpan: 3 },
        "",
        ""
      ]
  })

  if (name === 'Purchase Challan' && showAmmount) {
    entries = [...entries,
    [
      { text: 'Product Amount', alignment: 'right', colSpan: 2, bold: true, fontSize: 13 },
      '',
      { text: '', colSpan: 2 },
      '',
      { text: data.productAmount, width: 80, alignment: 'center', bold: true, fontSize: 13 }
    ],
    [
      { text: 'Discount', alignment: 'right', colSpan: 2, bold: true, fontSize: 13 },
      '',
      { text: '', colSpan: 2 },
      '',
      { text: data.discountAmount, width: 80, alignment: 'center', bold: true, fontSize: 13 }
    ],
    ]
  }

  let termAndCondition = ''
  if (data.termAndConditions !== '') {
    termAndCondition = {
      stack: [
        { text: 'Terms and Condition:', bold: true, fontSize: 10 },
        { text: '\n', fontSize: 2 },
        { text: data.termAndConditions, fontSize: 8 },
      ],
      colSpan: 2
    }
  }


  const docDefinition = {
    pageSize: 'A4',
    // pageOrientation: 'landscape',
    content: [
      {
        table: {
          headerRows: 0,
          widths: [15, '*', 60, 65, 85],
          dontBreakRows: true,
          body: [
            [
              {
                stack: [
                  { text: '\n', fontSize: 3 },
                  { text: 'SHRADDHA FIRE AND SAFETY SERVICES', bold: true, fontSize: 25 },
                  { text: '\n', fontSize: 3 },
                  { text: '119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT', fontSize: 10 }

                ],
                alignment: 'center',
                colSpan: 5,
              }, '', '', '', ''
            ],
            [
              { text: name, alignment: 'center', fontSize: 12, bold: true, colSpan: 5 },
              '', '', '', ''

            ],
            [
              {
                stack: [
                  { text: 'To,', fontSize: 10 },
                  { text: '\n', fontSize: 2 },
                  { text: data.customerName, bold: true, fontSize: 10 },
                  { text: '\n', fontSize: 2 },
                ],
                colSpan: 3
              },
              '', '',
              {
                stack: [
                  {
                    columns: [
                      { text: 'Challan No.', width: '*', fontSize: 10 },
                      { text: `: ${data.invoiceNo}`, width: '*', fontSize: 10, bold: true }
                    ]
                  },
                  { text: '\n', fontSize: 2 },
                  {
                    columns: [
                      { text: 'Date', width: '*', fontSize: 10 },
                      { text: `: ${dateFormate(data.invoiceDate, 'Date')}`, width: '*', fontSize: 10, bold: true }
                    ]
                  },
                ],
                colSpan: 2
              },
              '',
            ],
            showAmmount ? [
              { text: 'SR', alignment: 'center', fontSize: 11 },
              { text: 'Description Of Goods', alignment: 'center', fontSize: 11 },
              { text: 'Quantity', alignment: 'center', fontSize: 11 },
              { text: 'Rate', alignment: 'center', fontSize: 11 },
              { text: 'Amount', alignment: 'center', fontSize: 11 }
            ]
              :
              [
                { text: 'SR', alignment: 'center', fontSize: 11 },
                { text: 'Description Of Goods', alignment: 'center', fontSize: 11 },
                { text: 'Quantity', alignment: 'center', fontSize: 11, colSpan: 3 },
                "",
                ""
              ],
            ...entries,
            showAmmount ? [
              { text: `Total Amount`, alignment: 'right', colSpan: 2, bold: true, fontSize: 14 },
              '',
              { text: totalQuantity, alignment: 'center', bold: true, fontSize: 14 },
              '',
              { text: data.totalAmount, width: 80, alignment: 'center', bold: true, fontSize: 14 }
            ] :
              [
                { text: `Total Quantity`, alignment: 'right', colSpan: 2, bold: true, fontSize: 14 },
                '',
                { text: totalQuantity, alignment: 'center', bold: true, fontSize: 14, colSpan: 3 },
                '',
                ""
              ]
            ,
            [
              termAndCondition,
              '',
              {
                stack: [
                  { text: 'For, SHRADDHA FIRE AND SAFETY SERVICES', bold: true, fontSize: 11 },
                  { text: '\n' }, { text: '\n' }, { text: '\n' }, { text: '\n' },
                  { text: 'Authorised Signatory', fontSize: 9 },
                ],
                colSpan: 3
              },
              '', ''
            ]

          ]
        },
      }
    ],
    footer: {
      columns: [
        { image: imagebase64, fit: [100, 100], alignment: 'right' }
      ]
    }
  }
  // pdfMake.createPdf(docDefinition).download(`${fileName}_${data.invoiceNo}`) // to download   
  pdfMake.createPdf(docDefinition).open()

}

export const downloadPRFile = (data, name) => {
  let fileName = name.split(' ').join('_')
  let entries = data.items.map((ele, ind) => {
    return (
      [{ text: ind + 1, alignment: 'center', border: [true, false, true, true] },
      { text: ele.productName, alignment: 'center', border: [true, false, true, true] },
      { text: ele.reqQuantity, alignment: 'center', border: [true, false, true, true] },
      { text: ele.deliveredQuantity, alignment: 'center', border: [true, false, true, true] },
      { text: ele.priority, alignment: 'center', border: [true, false, true, true] },
      { text: ele.unit, alignment: 'center', border: [true, false, true, true] },
      { text: ele.desc, alignment: 'center', border: [true, false, true, true] },
      { text: ele.make, alignment: 'center', border: [true, false, true, true] }]
    )
  })

  const docDefinition = {
    pageSize: 'A4',
    // pageOrientation: 'landscape',
    content: [
      {
        table: {
          headerRows: 0,
          widths: [15, '*', 50, 50, 50, 50, 80, 80],
          dontBreakRows: true,
          body: [
            [
              {
                stack: [
                  { text: '\n', fontSize: 3 },
                  { text: 'SHRADDHA FIRE AND SAFETY SERVICES', bold: true, fontSize: 25 },
                  { text: '\n', fontSize: 3 },
                  { text: '119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT', fontSize: 10 }

                ],
                alignment: 'center',
                colSpan: 8,
              }, '', '', '', '', '', '', ''
            ],
            [
              {
                stack: [
                  { text: name, alignment: 'center', fontSize: 12, bold: true, colSpan: 5 },
                ],
                alignment: 'center',
                colSpan: 8,
              }, '', '', '', '', '', '', ''
            ],
            [
              {
                stack: [
                  {
                    columns: [
                      { text: 'Purchase Request No.', width: '*', fontSize: 10 },
                      { text: `: ${data?.purchaseRequestNumber ? data?.purchaseRequestNumber : ''}`, width: '*', fontSize: 10, bold: true }
                    ]
                  },
                  { text: '\n', fontSize: 2 },
                  {
                    columns: [
                      { text: 'Project Name.', width: '*', fontSize: 10 },
                      { text: `: ${data?.project?.partyName}`, width: '*', fontSize: 10, bold: true }
                    ]
                  },
                  { text: '\n', fontSize: 2 },
                  {
                    columns: [
                      { text: 'Status.', width: '*', fontSize: 10 },
                      { text: `: ${data.status}`, width: '*', fontSize: 10, bold: true }
                    ]
                  },
                ],
                colSpan: 8
              },
              '', '', '', '', '', '', ''
            ],
            [
              {
                stack: [
                  { text: '', alignment: 'center', fontSize: 12, border: [false, false, false, false] },
                ],
                alignment: 'center',
                colSpan: 8,
              }, '', '', '', '', '', '', ''
            ],
            [
              { text: 'SR', alignment: 'center', fontSize: 11 },
              { text: 'Product', alignment: 'center', fontSize: 11 },
              { text: 'Req Qty', alignment: 'center', fontSize: 11 },
              { text: 'Delv Qty', alignment: 'center', fontSize: 11 },
              { text: 'Priority', alignment: 'center', fontSize: 11 },
              { text: 'Unit', alignment: 'center', fontSize: 11 },
              { text: 'Description', alignment: 'center', fontSize: 11 },
              { text: 'make', alignment: 'center', fontSize: 11 },
            ],
            ...entries,
          ]
        },
      }
    ],
    footer: {
      columns: [
        { image: imagebase64, fit: [100, 100], alignment: 'right' }
      ]
    }
  }
  // // pdfMake.createPdf(docDefinition).download(`${fileName}_${data.invoiceNo}`) // to download   
  pdfMake.createPdf(docDefinition).open()

}

export const downloadQuoatationFile = (data, name, quotationdata, flag) => {
  let centralTax = 9
  let stateTax = 9
  let IGST = 0
  //let totalQuantity = data.reduce((sum, ele) => sum + ele.quantity, 0);
  let subTotal = +data.reduce((sum, ele) => sum + (ele.retailSalePrice * (ele.quantity ? ele.quantity : ele.reqQuantity ? ele.reqQuantity : 0)), 0).toFixed(0);
  let commonDiscountAmount = quotationdata.commonDiscount ? +(subTotal * (quotationdata.commonDiscount / 100)).toFixed(0) : '-'
  //let total = +data.reduce((sum, ele) => sum + (ele.retailSalePrice * ele.quantity - ele.discount), 0).toFixed(0);
  let total = +(subTotal - (quotationdata.commonDiscount ? commonDiscountAmount : 0)).toFixed(0)
  let TotalcentralTax = +(total * centralTax / 100).toFixed(0)
  let TotalstateTax = +(total * stateTax / 100).toFixed(0)
  let TotalIGST = +(total * IGST / 100).toFixed(0)
  let finalTotal = (total + TotalcentralTax + TotalstateTax + TotalIGST)
  let googleLocation = quotationdata.googleLocation ? quotationdata.googleLocation : ""
  let finalTotalinwords = numberToWords.toWords(finalTotal).split(' ').map((word, index) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
  let finalTotalinwordsBeforeGst = numberToWords.toWords(total).split(' ').map((word, index) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
  let entries = data.map((ele, ind) => {
    return ([
      { text: ind + 1, alignment: 'center', border: [true, false, true, false] },
      { text: ele.productName, alignment: 'center', fontSize: 10, border: [true, false, false, false], colSpan: 2 },
      {},
      { text: ele.hsnsacCode, alignment: 'center', fontSize: 10, border: [true, false, false, false] },
      { text: `${ele.gst} %`, alignment: 'center', fontSize: 10, border: [true, false, false, false] },
      { text: `${ele.quantity ? ele.quantity : ele.reqQuantity} ${ele.unitOfMeasurement ? ele.unitOfMeasurement : ele.unit ? ele.unit : ''}`, alignment: 'center', fontSize: 10, border: [true, false, false, false] },
      { text: ele.retailSalePrice, alignment: 'right', fontSize: 10, border: [true, false, false, false] },
      // { text: ele.discount, alignment: 'right', fontSize: 10, border: [true, false, false, false] },
      // { text: (ele.retailSalePrice * ele.quantity ? ele.quantity : ele.reqQuantity) - (ele.retailSalePrice * ele.quantity ? ele.quantity : ele.reqQuantity * ele.discount / 100), alignment: 'right', fontSize: 10, border: [true, false, true, false], colSpan:2 },
      // { text: (ele.retailSalePrice * ele.quantity ? ele.quantity : ele.reqQuantity - ele.discount), alignment: 'right', fontSize: 10, border: [true, false, true, false], colSpan:2 },
      { text: (ele.retailSalePrice * (ele.quantity ? ele.quantity : ele.reqQuantity)), alignment: 'right', fontSize: 10, border: [true, false, true, false], colSpan:2 },
    ])
  });

  const docDefinition = {
    pageSize: 'A4',
    content: [
      {
        table: {
          headerRows: 0,
          widths: [35, '*', 50, 50, 45, 45, 70, 40, 70],
          dontBreakRows: true,
          body: [
            [
              {
                stack: [
                  { text: '\n', fontSize: 3 },
                  { text: 'SHRADDHA FIRE AND SAFETY SERVICES', bold: true, fontSize: 14 },
                  { text: '\n\n', fontSize: 3 },
                  { text: '119, Zenon, Nr Opera House, Opp Unique Hospital, Off Ring Road, Udhna, Surat-395002', fontSize: 12 },
                  { text: '\n', fontSize: 3 },
                  { text: 'Tel/M : 9724314932', fontSize: 12 },
                  { text: '\n', fontSize: 3 },
                  { text: 'Email ID : shraddhafire25@gmail.com', fontSize: 12 },
                  { text: '\n', fontSize: 3 },
                  { text: 'GSTIN : 24AVKPP4317L1ZA', fontSize: 12 },
                ],
                alignment: 'left',
                colSpan: 5,
                border: [false, false],
              }, '', '', '', '',
              {
                stack: [
                  { image: imagebase64, fit: [200, 200] },
                ],
                alignment: 'center',
                colSpan: 4,
                border: [false, false],
              }, '', '', ''
            ],
            [
              {
                stack: [
                  { text: '\n', fontSize: 3 },
                ],
                colSpan: 9,
                border: [false, false],
              }, '', '', '', '', '', '', '', ''
            ],
            [
              {
                stack: [
                  {
                    columns: [
                      { text: name, alignment: 'center', fontSize: 13, bold: true, width: "65%" },
                      { text: '[  ] Original [  ] Duplicate [  ] Transpoter', margin: [0, 1], alignment: 'right', fontSize: 10, bold: true, width: "35%" }
                    ]
                  }
                ],
                colSpan: 9,
              }, '', '', '', '', '', '', '', ''
            ],
            [
              flag === "Enquiry" ?
                {
                  stack: [
                    // { text: 'To ,', fontSize: 12 },
                    { text: quotationdata?.partyName ?? '', bold: true, fontSize: 12 },
                    { text: quotationdata?.location ? [
                      { text: `Address: ${quotationdata?.location}`, fontSize: 12 },
                      quotationdata?.googleLocation ? { text: '\nClick Here' , fontSize: 12, color: 'blue', link:  quotationdata?.googleLocation ? googleLocation : "", decoration: 'underline',} : "",
                    ] :  quotationdata?.location ? `Address : ${quotationdata?.location}` : '', fontSize: 12},
                    { text: quotationdata?.contactPersonNumber ? `Phone : ${quotationdata?.contactPersonNumber}` : '', fontSize: 12 },
                    { text: quotationdata?.email ? `Email : ${quotationdata?.email}` : '', fontSize: 12 },
                    // { text: quotationdata?.enquiryDate ? `EnquiryDate : ${quotationdata?.enquiryDate}` : '', fontSize: 12 },
                    // { text: quotationdata?.visitDate ? `Date : ${quotationdata?.visitDate}` : '', fontSize: 12 },
                    // { text: 'KANBIBUAG, R.S.NO. 43 PAIKI , STATION ROAD BHARUCH 391240', fontSize: 12 },
                    // { text: 'State : Gujrat, State Code : 24', fontSize: 12 },
                    // { text: 'GSTIN : 24AAECR4575L1Zo', fontSize: 12 },
                    // { text: 'PAN NO : AAECR4575L', fontSize: 12 },
                  ],
                  colSpan: 6,
                }
                :
                {
                  stack: [
                    // { text: 'To ,', fontSize: 12 },
                    { text: quotationdata?.customerData?.accountName ?? '', bold: true, fontSize: 12 },
                    // { text: 'NEW CIVIL HOSPITAL BHARUCH COMPOUND', fontSize: 12, bold: true, },
                    { text: quotationdata?.customerData?.address ? `Address : ${quotationdata?.customerData?.address}` : '', fontSize: 12 },
                    { text: quotationdata?.GST ? `GSTIN : ${quotationdata?.GST}` : '', fontSize: 12 },
                    // { text: 'KANBIBUAG, R.S.NO. 43 PAIKI , STATION ROAD BHARUCH 391240', fontSize: 12 },
                    // { text: 'State : Gujrat, State Code : 24', fontSize: 12 },
                    // { text: 'GSTIN : 24AAECR4575L1Zo', fontSize: 12 },
                    // { text: 'PAN NO : AAECR4575L', fontSize: 12 },
                  ],
                  colSpan: 6,
                },
              {},
              {},
              {},
              {},
              {},
              {
                stack: [
                  { text: `Quotation No       : ${quotationdata.quotationNo}`, fontSize: 12 },
                  { text: `Date                       : ${moment(quotationdata.createdAt).format('DD-MM-YYYY')}`, fontSize: 12 },
                ],
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              { text: 'SR', alignment: 'center', fontSize: 11 },
              { text: 'Description Of Goods', alignment: 'center', fontSize: 11, colSpan: 2 },
              {},
              { text: 'HSN/SAC', alignment: 'center', fontSize: 11 },
              { text: 'GST', alignment: 'center', fontSize: 11 },
              { text: 'Quantity', alignment: 'center', fontSize: 11 },
              { text: 'Rate', alignment: 'center', fontSize: 11 },
              // { text: 'Disc.(%)', alignment: 'center', fontSize: 11 },
              { text: 'Sub Total', alignment: 'center', fontSize: 11, colSpan: 2 },
            ],
            ...entries,
            [
              {},
              {},
              {},
              {},
              {},
              {},
              {text:'Sub Total',alignment: 'left', fontSize: 10, bold: true },
              {text: `${subTotal}`, colSpan:2,alignment: 'right', fontSize: 10, bold: true},
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {},
              {text: `Discount${quotationdata.commonDiscount ? ` (${quotationdata.commonDiscount}%)`: ''}`,alignment: 'left', fontSize: 10, bold: true },
              {text: `${commonDiscountAmount}`, colSpan: 2, alignment: 'right', fontSize: 10, bold: true },
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {},
              {text: 'Total',alignment: 'left', fontSize: 10, bold: true },
              {text: `${total}`, colSpan: 2, alignment: 'right', fontSize: 10, bold: true },
            ],
            [
              {
                stack: [
                  {
                    columns: [
                      { text: `Amt. Chargeable (words)`, alignment: 'left', fontSize: 10, bold: false, width: "23%" },
                      { text: `${finalTotalinwordsBeforeGst} Rupees Only `, alignment: 'left', fontSize: 10, bold: true, width: "70%" },
                      { text: 'E. & OE.', alignment: 'right', fontSize: 10, bold: true, width: "7%" },
                    ]
                  }
                ],
                colSpan: 9,
              }, '', '', '', '', '', '', '', ''
            ],
            [
              { text: 'Taxable Value', alignment: 'center', fontSize: 11, colSpan: 2, rowSpan: 2 },
              {},
              { text: 'Central Tax', alignment: 'center', fontSize: 11, colSpan: 2, },
              {},
              { text: 'State Tax', alignment: 'center', fontSize: 11, colSpan: 2, },
              {},
              { text: 'IGST', alignment: 'center', fontSize: 11, colSpan: 2, },
              {},
              { text: 'Total \nTax Amt.', alignment: 'center', fontSize: 11, rowSpan: 2 }
            ],
            [
              {},
              {},
              { text: 'Rate.', alignment: 'center', fontSize: 11 },
              { text: 'Amount.', alignment: 'center', fontSize: 11 },
              { text: 'Rate.', alignment: 'center', fontSize: 11 },
              { text: 'Amount.', alignment: 'center', fontSize: 11 },
              { text: 'Rate.', alignment: 'center', fontSize: 11 },
              { text: 'Amount.', alignment: 'center', fontSize: 11 },
              {}
            ],
            [
              { text: total, alignment: 'right', fontSize: 10, colSpan: 2 },
              {},
              { text: `${centralTax}%`, alignment: 'center', fontSize: 10 },
              { text: TotalcentralTax, alignment: 'center', fontSize: 10 },
              { text: `${stateTax}%`, alignment: 'center', fontSize: 10 },
              { text: TotalstateTax, alignment: 'center', fontSize: 10 },
              { text: `${IGST}%`, alignment: 'center', fontSize: 10 },
              { text: TotalIGST, alignment: 'center', fontSize: 10 },
              { text: (TotalcentralTax + TotalstateTax + TotalIGST), alignment: 'center', fontSize: 10 },
            ],
            [
              { text: 'TOTAL', alignment: 'right', fontSize: 10, bold: true },
              { text: total, alignment: 'right', fontSize: 10 },
              { text: '', alignment: 'center', fontSize: 10 },
              { text: TotalcentralTax, alignment: 'center', fontSize: 10 },
              { text: '', alignment: 'center', fontSize: 10 },
              { text: TotalstateTax, alignment: 'center', fontSize: 10 },
              { text: '', alignment: 'center', fontSize: 10 },
              { text: TotalIGST, alignment: 'center', fontSize: 10 },
              { text: finalTotal, alignment: 'center', fontSize: 10 },
            ],
            [
              {
                stack: [
                  {
                    columns: [
                      { text: `Amt. Chargeable (words)`, alignment: 'left', fontSize: 10, bold: false, width: "23%" },
                      { text: `${finalTotalinwords} Rupees Only `, alignment: 'left', fontSize: 10, bold: true, width: "77%" },
                    ]
                  }
                ],
                colSpan: 9,
              }, '', '', '', '', '', '', '', ''
            ],
            [
              { text: 'Term And Condition : ', alignment: 'left', fontSize: 10, colSpan: 5 },
              {},
              {},
              {},
              {},
              {
                stack: [
                  { text: "Company's Bank Details", alignment: 'left', fontSize: 10, bold: true },
                  { text: `Name               :\xa0HDFC BANK`, alignment: 'left', fontSize: 10, },
                  { text: `A/C. No           :\xa050200011153791`, alignment: 'left', fontSize: 10, },
                  { text: `IFSC Code       :\xa0HDFC0009213`, alignment: 'left', fontSize: 10, },
                ],
                colSpan: 4
              },
              {},
              {},
              {},
            ]
          ]
        },
      }
    ],
  }

  pdfMake.createPdf(docDefinition).download(`fire_safety_quoatation`);
  // pdfMake.createPdf(docDefinition).open();
}

export const downloadFiles = (datas, total, discount) => {

  const data = datas.map((ele) => {
    return {
      type: ele.productInfo.productName,
      quantity: ele.usedQty,
      price: ele.labourCharge,
      total: ele.labourCharge * ele.usedQty
    }
  });

  const labour_bill_projectName = datas[0]?.projectInfo?.partyName;
  const labour_bill_contractorName = datas[0]?.contractorInfo?.name ?? '-';
  const labour_bill_contractorMobile_no = datas[0]?.contractorInfo?.mobileNo ?? '-';
  const labour_bill_contractorBank_name = datas[0]?.contractorInfo?.bank_name ?? '-';
  const labour_bill_contractorAccount_no = datas[0]?.contractorInfo?.account_number ?? '-';
  const labour_bill_contractorIFSC = datas[0]?.contractorInfo?.ifsc ?? '-';
  const labour_bill_contractorPAN_no = datas[0]?.contractorInfo?.pan_number ?? '-';
  const labour_bill_contractorAadhaar_no = datas[0]?.contractorInfo?.account_number ?? '-';
  const labour_bill_invoice_date = moment(datas[0]?.labour_bill_invoice_date).format('DD - MMM - YYYY');

  const labour_bill_total = total ?? data.reduce((sum, ele) => sum + ele.total, 0);
  const labour_bill_discount = discount ?? 0;
  const labour_bill_final_total = (labour_bill_total - labour_bill_discount);

  let entries = data.map((ele, ind) => {
    return ([
      { text: ind + 1, alignment: 'center', fontSize: 10, bold: true },
      { text: ele.type, alignment: 'center', fontSize: 10, bold: true },
      { text: ele.quantity, alignment: 'center', fontSize: 10, bold: true },
      { text: ele.price, alignment: 'center', fontSize: 10, bold: true },
      { text: ele.total, alignment: 'center', fontSize: 10, bold: true },
    ]);
  });

  const docDefinition = {
    pageSize: 'A4',
    content: [
      {
        table: {
          headerRows: 0,
          widths: [50, "*", 70, 70, 50],
          dontBreakRows: true,
          body: [
            [
              {
                stack: [
                  { image: imagebase64 },
                  { text: '\n', fontSize: 3 },
                  { text: 'SHRADDHA FIRE AND SAFETY SERVICES', bold: true, fontSize: 25, color: 'red', decoration: 'underline' },
                  { text: '\n', fontSize: 3 },
                  { text: 'SMC REGISTERED FIRE PROTECTION CONSULANT', bold: true, fontSize: 18, color: 'red' },
                  { text: '\n', fontSize: 3 },
                  { text: '119, ZENON, NEAR OPERA HOUSE, OPP, UNIQUE HOSPITAL, OFF.RING ROAD, UDHNA, SURAT', fontSize: 11, color: "#00b1ed" },
                  { text: '\n', fontSize: 3 },
                  { text: 'MO : 9274314932/8238443617', fontSize: 11, color: "#00b1ed" },
                  { text: '\n\n\n\n', fontSize: 3 },
                  { text: 'CONTRACTER BILL', bold: true, fontSize: 17, color: "#00b1ed" },
                  { text: '\n', fontSize: 3 },
                ],
                alignment: 'center',
                border: [false, false],
                colSpan: 5,
              }, '', '', '', ''
            ],
            [
              {
                stack: [
                  { text: `[ ${labour_bill_projectName} ]`, bold: true, fontSize: 14, margin: [0, 0, 0, 3] },
                ],
                colSpan: 5,
                alignment: 'center',
                border: [false, false, false, false]
              },'','','',''
            ],
            [
              {
                stack: [
                  { text: `Contractor Name : ${labour_bill_contractorName}`, bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                  { text: `Date : ${labour_bill_invoice_date}`, bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                  { text: `Mobile No. : ${labour_bill_contractorMobile_no}`, bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                  { text: `Bank Name : ${labour_bill_contractorBank_name}`, bold: true, fontSize: 12, margin: [0, 3, 0, 3] }
                ],
                colSpan: 2,
                border: [false, false, false, false]
              },'',
              {
                stack: [
                  { text: `Account No. : ${labour_bill_contractorAccount_no}`, bold: true, fontSize: 12, margin: [0, 0, 0, 3] },
                  { text: `IFSC : ${labour_bill_contractorIFSC}`, bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                  { text: `PAN No. : ${labour_bill_contractorPAN_no}`, bold: true, fontSize: 12, margin: [0, 3, 0, 3] },
                  { text: `Aadhaar No. : ${labour_bill_contractorAadhaar_no}`, bold: true, fontSize: 12, margin: [0, 3, 0, 3] }
                ],
                colSpan: 3,
                border: [false, false, false, false]
              },'',''
            ],

            [
              { text: 'SR.NO', alignment: 'center', fontSize: 11, bold: true, fillColor: '#00b1ef', color: 'white' },
              { text: 'TYPE', alignment: 'center', fontSize: 11, bold: true, fillColor: '#00b1ef', color: 'white' },
              { text: 'QUANTITY', alignment: 'center', fontSize: 11, bold: true, fillColor: '#00b1ef', color: 'white' },
              { text: 'PRICE', alignment: 'center', fontSize: 11, bold: true, fillColor: '#00b1ef', color: 'white' },
              { text: 'TOTAL', alignment: 'center', fontSize: 11, bold: true, fillColor: '#00b1ef', color: 'white' },
            ],
            ...entries,
            [
              { text: "TOTAL", colSpan: 4, alignment: 'right', fontSize: 11, bold: true },
              {},
              {},
              {},
              { text: labour_bill_total, alignment: 'center', fontSize: 11, bold: true },
            ],
            [
              { text: "DISCOUNT", colSpan: 4, alignment: 'right', fontSize: 11, bold: true },
              {},
              {},
              {},
              { text: labour_bill_discount, alignment: 'center', fontSize: 11, bold: true },
            ],
            [
              { text: "FINAL TOTAL", colSpan: 4, alignment: 'right', fontSize: 11, bold: true },
              {},
              {},
              {},
              { text: labour_bill_final_total, alignment: 'center', fontSize: 11, bold: true },
            ],
            [
              {
                stack: [
                  { text: '\n\n\n\n', fontSize: 3 },
                  { text: 'TERMS & CONDITIONS :-', fontSize: 10, bold: true },
                  { text: '\n\n\n', fontSize: 3 },
                  { text: '1 - WELDING ROAD, CUTTING DISC & PAINTING  WORK IN LABOUR ( 2+2 RED  AND YELLOW ) IS YOUR SCOOP.', fontSize: 10, bold: true },
                  { text: '\n\n\n', fontSize: 3 },
                  { text: '2 - QUOTATION HAS BEEN MADE PER ITEM RATE.', fontSize: 10, bold: true },
                  { text: '\n\n\n', fontSize: 3 },
                  { text: '3 - TESTING & COMMISING FINAL IS YOUR SCOOP.', fontSize: 10, bold: true },
                  { text: '\n\n\n', fontSize: 3 },
                  { text: '4 - 10% ADVANCE & 70% AFTER COMPLETE WORK & 20% AFTER FINAL TESTING & COMMISING DONE.', fontSize: 10, bold: true },
                  { text: '\n\n\n\n', fontSize: 3 },
                ],
                alignment: 'start',
                border: [false, false],
                colSpan: 5,
              }, '', '', '', ''
            ],
            [
              { text: '1.', alignment: 'center', fontSize: 11, bold: true },
              { text: 'MAN-POWER', alignment: 'center', fontSize: 11, bold: true },
              { stack: [{ text: '', fontSize: 11, bold: true }], alignment: 'center', colSpan: 3 },
              {},
              {},
            ],
            [
              { text: 'A.', alignment: 'center', fontSize: 11, bold: true },
              { text: 'WELDER PER DAY', alignment: 'center', fontSize: 11, bold: true },
              { stack: [{ text: '', fontSize: 11, bold: true }], alignment: 'center', colSpan: 3 },
              {},
              {},
            ],
            [
              { text: 'B.', alignment: 'center', fontSize: 11, bold: true },
              { text: 'HELPER PER DAY', alignment: 'center', fontSize: 11, bold: true },
              { stack: [{ text: '', fontSize: 11, bold: true }], alignment: 'center', colSpan: 3 },
              {},
              {},
            ],
            [
              { text: 'C.', alignment: 'center', fontSize: 11, bold: true },
              { text: 'FILTER PER DAY', alignment: 'center', fontSize: 11, bold: true },
              { stack: [{ text: '', fontSize: 11, bold: true }], alignment: 'center', colSpan: 3 },
              {},
              {},
            ],
            [
              {
                stack: [
                  { text: '\n\n\n\n', fontSize: 3 },
                  { text: 'TERMS & CONDITIONS :-', fontSize: 10, bold: true },
                  { text: '\n\n\n', fontSize: 3 },
                  { text: '1 - TRAVELLING CHARGES IS YOUR SCHOOP.', fontSize: 10, bold: true },
                  { text: '\n\n\n', fontSize: 3 },
                  { text: '2 - TEA & LUNCH & COFFEE IS YOUR SCHOOP.', fontSize: 10, bold: true },
                  { text: '\n\n\n\n', fontSize: 3 },
                ],
                alignment: 'start',
                border: [false, false],
                colSpan: 5,
              }, '', '', '', ''
            ],
          ]
        },
        layout: {
          hLineStyle: function (i, node) {
            return { dash: { length: 1 } };
          },
          vLineStyle: function (i, node) {
            return { dash: { length: 1 } };
          },
        },
      },
    ],
    background: function (currentPage, pageSize) {
      return {
        canvas: [

          // Top border
          {
            type: 'rect',
            x: 20,
            y: 20,
            w: pageSize.width - 40,
            h: 0,
            lineWidth: 1,
            lineColor: '#9dd9e4',
          },
          {
            type: 'rect',
            x: 23,
            y: 23,
            w: pageSize.width - 46,
            h: 0,
            lineWidth: 2,
            lineColor: '#53b2db',
          },
          {
            type: 'rect',
            x: 26,
            y: 26,
            w: pageSize.width - 52,
            h: 0,
            lineWidth: 1,
            lineColor: '#9dd9e4',
          },

          // Bottom border
          {
            type: 'rect',
            x: 20,
            y: pageSize.height - 20,
            w: pageSize.width - 40,
            h: 0,
            lineWidth: 1,
            lineColor: '#9dd9e4',
          },
          {
            type: 'rect',
            x: 23,
            y: pageSize.height - 23,
            w: pageSize.width - 46,
            h: 0,
            lineWidth: 2,
            lineColor: '#53b2db',
          },
          {
            type: 'rect',
            x: 26,
            y: pageSize.height - 26,
            w: pageSize.width - 52,
            h: 0,
            lineWidth: 1,
            lineColor: '#9dd9e4',
          },

          // Left border
          {
            type: 'rect',
            x: 20,
            y: 20,
            w: 0,
            h: pageSize.height - 40,
            lineWidth: 1,
            lineColor: '#9dd9e4',
          },
          {
            type: 'rect',
            x: 23,
            y: 23,
            w: 0,
            h: pageSize.height - 46,
            lineWidth: 2,
            lineColor: '#53b2db',
          },
          {
            type: 'rect',
            x: 26,
            y: 26,
            w: 0,
            h: pageSize.height - 52,
            lineWidth: 1,
            lineColor: '#9dd9e4',
          },

          // Right border
          {
            type: 'rect',
            x: pageSize.width - 20,
            y: 20,
            w: 0,
            h: pageSize.height - 40,
            lineWidth: 1,
            lineColor: '#9dd9e4',
          },
          {
            type: 'rect',
            x: pageSize.width - 23,
            y: 23,
            w: 0,
            h: pageSize.height - 46,
            lineWidth: 2,
            lineColor: '#53b2db',
          },
          {
            type: 'rect',
            x: pageSize.width - 26,
            y: 26,
            w: 0,
            h: pageSize.height - 52,
            lineWidth: 1,
            lineColor: '#9dd9e4',
          },

        ],
      };
    },
    footer: {
      columns: [
        { image: imagebase64, fit: [100, 100], alignment: 'right', margin: [-30, -30, 30, 30] }
      ]
    }
  }

  pdfMake.createPdf(docDefinition).download(`fire_safety_labour_bill`);
  pdfMake.createPdf(docDefinition).open();
}

function getImageBase64(url, callback) {
  var img = new Image();
  img.setAttribute('crossOrigin', 'anonymous');

  img.onload = function () {
    var canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(this, 0, 0);

    var dataURL = canvas.toDataURL(logo);
    callback(dataURL);
  };

  img.src = url;
}

// Example usage:
let imagebase64;
var imageUrl = logo;
getImageBase64(imageUrl, function (base64) {
  imagebase64 = base64
});