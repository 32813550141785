import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { dateFormate } from '../../helper/utils'
import { getAllProductAction } from '../../redux/actions/productAction'
import { getAllProjectAction } from '../../redux/actions/projectAction'
import { getPurchaseRequestByIdAction } from '../../redux/actions/purchaseRequest'
import { getReturnMaterialByIdAction } from '../../redux/actions/returnMaterialAction'

const ViewReturnMaterial = ({ show, onHide, viewId }) => {

    const dispatch = useDispatch()

    const [returnMaterial, setReturnMaterial] = useState({
        project: '',
        returnDate: new Date(),
    })
    const [items, setItems] = useState([
        {
            productCode: '',
            productName: '',
            quantity: '',
            desc: '',
            make: '',
            unit: ''
        }
    ])

    const getReturnMaterialByIdData = useSelector((state) => state.get_Return_Material_By_Id.getReturnMaterialById)
    const getAllProjectData = useSelector((state) => state.get_All_Project.getAllProject.data)
    const getAllProductData = useSelector((state) => state.get_All_Product.getAllProduct.data)

    useEffect(() => {
        let body = '';
        dispatch(getAllProjectAction(body))
        dispatch(getAllProductAction())
    }, [])

    useEffect(() => {
        if (viewId) {
            dispatch(getReturnMaterialByIdAction(viewId))
        }
    }, [viewId])

    useEffect(() => {
        if (getReturnMaterialByIdData && getReturnMaterialByIdData.data && viewId) {
            setReturnMaterial({
                project: selectValue(getReturnMaterialByIdData.data.project, "project"),
                returnDate: getReturnMaterialByIdData.data.returnDate,
            })
            setItems(getReturnMaterialByIdData.data.items.map((ele) => {
                return {
                    productCode: getAllProductData?.find((item) => item?._id === ele.productId)?.productCode,
                    productName: ele.productName,
                    quantity: ele.quantity,
                    desc: ele.desc,
                    make: ele.make,
                    unit: ele.unit
                }
            }))
        }
    }, [getReturnMaterialByIdData])

    const selectValue = (value, name) => {
        if (name === "project") {
            return getAllProjectData?.find((ele) => ele?._id === value)?.partyName
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Return Material</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='text-center'>SHRADDHA FIRE AND SAFETY SERVICES</h4>
                <h6 className='text-center'>119,ZENON,NEAR OPERA HOUSE,OPP,UNIQUE HOSPITAL,OFF.RING ROAD,UDHNA,SURAT</h6>
                <hr></hr>
                <Row>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-pr-modal'>
                                <tr>
                                    <td>Project Name</td>
                                    <td>{returnMaterial?.project}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mx-0 mt-3">
                            <table className='table viewer-pr-modal'>
                                <tr>
                                    <td>Return Date</td>
                                    <td>{returnMaterial?.returnDate ? dateFormate(returnMaterial.returnDate, "Date") : ''}</td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="">
                        <h2 className="Steps-header">Return Material lists</h2>
                    </Col>
                </Row>
                <table className='table viewer-project-pr-steps-table'>
                    <tr>
                        <th>Sr</th>
                        <th>Product Code</th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th>Desc</th>
                        <th>Make</th>
                        <th>Unit</th>
                    </tr>
                    {items.map((item, i) =>
                        <tr key={i}>
                            <td className='vertical-top'>{i + 1}</td>
                            <td className='vertical-top'>{item.productCode}</td>
                            <td className='vertical-top'>{item.productName}</td>
                            <td className='vertical-top'>{item.quantity}</td>
                            <td className='vertical-top'>{item.desc}</td>
                            <td className='vertical-top'>{item.make}</td>
                            <td className='vertical-top'>{item.unit}</td>
                        </tr>

                    )}

                </table>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className='cancel-button' onClick={onHide}>Cancel</button>
            </Modal.Footer> */}

        </Modal>
    )
}

export default ViewReturnMaterial